import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import { companyRequestListKnownAction } from '../reducers/projectListReducer';
import {
  companyRequestKnownAction,
  FProjectRequestStatusEnum,
} from '../reducers/projectRequestReducer';
import debug from '../utils/debug';
import { mutate, query } from '../utils/graphql';
import {
  ADMIN_APPROVE_PROJECT_REQUEST,
  ADMIN_GET_All_PROJECT_REQUEST,
  ADMIN_GET_PROJECT_REQUEST_BY_ID,
  ANONYMOUS_GET_ALL_PROJECT_REQUEST,
  ANONYMOUS_GET_LAST_CREATED_PROJECT_REQUEST,
  ANONYMOUS_GET_LAST_CREATED_PROJECT_REQUEST_NO_PICTURES,
  ANONYMOUS_GET_LAST_UPDATE_PROJECT_REQUEST,
  ANONYMOUS_GET_LAST_UPDATE_PROJECT_REQUEST_NO_PICTURES,
  ANONYMOUS_GET_PROJECT_REQUEST_BY_ID,
  CONSULTANT_GET_ALL_PROJECT_REQUEST,
  CONSULTANT_GET_ALL_PROJECT_REQUEST_NO_PICTURES,
  CONSULTANT_GET_PROJECT_REQUEST_BY_ID,
  GET_PROJECT_REQUEST_ALL,
  GET_PROJECT_REQUEST_DETAILS,
} from '../utils/graphqlStrings';
export const COMPANY_KNOWN = 'COMPANY_KNOWN';
export const COMPANY_VERIFICATION_KNOWN = 'COMPANY_VERIFICATION_KNOWN';

export const getAllProjectRequest: Function = () => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('getAllProjectRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(GET_PROJECT_REQUEST_ALL);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.companyGetAllProjectRequest) {
    const companyGetAllProjectRequest = data.companyGetAllProjectRequest;
    debug(' getAllProjectRequest successfully');
    debug(companyGetAllProjectRequest);
    dispatch(loadingEndAction());
    dispatch(companyRequestListKnownAction(companyGetAllProjectRequest));
    // dispatch(
    //   showNotificationAction({
    //     severity: 'success',
    //     message: '获取项目项目成功',
    //   })
    // );
    return data.companyGetAllProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const consultantGetAllProjectRequest: Function = () => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('consultantGetAllProjectRequest');

  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(CONSULTANT_GET_ALL_PROJECT_REQUEST);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.consultantGetAllProjectRequest) {
    const consultantGetAllProjectRequest = data.consultantGetAllProjectRequest;
    debug(' consultantGetAllProjectRequest successfully');
    debug(consultantGetAllProjectRequest);
    dispatch(loadingEndAction());
    dispatch(companyRequestListKnownAction(consultantGetAllProjectRequest));
    return data.consultantGetAllProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const consultantGetAllProjectNoPicturesRequest: Function = () => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('consultantGetAllProjectRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(CONSULTANT_GET_ALL_PROJECT_REQUEST_NO_PICTURES);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.consultantGetAllProjectRequest) {
    const consultantGetAllProjectRequest = data.consultantGetAllProjectRequest;
    debug(' consultantGetAllProjectRequest successfully');
    debug(consultantGetAllProjectRequest);
    dispatch(loadingEndAction());
    dispatch(companyRequestListKnownAction(consultantGetAllProjectRequest));
    return data.consultantGetAllProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const anonymousGetAllProjectRequest: Function = () => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('anonymousGetAllProjectRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(ANONYMOUS_GET_ALL_PROJECT_REQUEST);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.anonymousGetAllProjectRequest) {
    const anonymousGetAllProjectRequest = data.anonymousGetAllProjectRequest;
    debug(' anonymousGetAllProjectRequest successfully');
    debug(anonymousGetAllProjectRequest);
    dispatch(loadingEndAction());
    dispatch(companyRequestListKnownAction(anonymousGetAllProjectRequest));
    return data.anonymousGetAllProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const anonymousGetLastCreatedProjectRequest: Function = (size: Number) => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('anonymousGetLastCreatedProjectRequest');

  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(ANONYMOUS_GET_LAST_CREATED_PROJECT_REQUEST, {
    size,
  });

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.anonymousGetLastCreatedProjectRequest) {
    const anonymousGetLastCreatedProjectRequest = data.anonymousGetLastCreatedProjectRequest;
    debug(' anonymousGetLastCreatedProjectRequest successfully');
    debug(anonymousGetLastCreatedProjectRequest);
    dispatch(loadingEndAction());
    // dispatch(companyRequestListKnownAction(anonymousGetLastCreatedProjectRequest));
    return data.anonymousGetLastCreatedProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};
export const anonymousGetLastCreatedProjectNoPicturesRequest: Function = (size: Number) => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('anonymousGetLastCreatedProjectRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(
    ANONYMOUS_GET_LAST_CREATED_PROJECT_REQUEST_NO_PICTURES,
    {
      size,
    }
  );

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.anonymousGetLastCreatedProjectRequest) {
    const anonymousGetLastCreatedProjectRequest = data.anonymousGetLastCreatedProjectRequest;
    debug(' anonymousGetLastCreatedProjectRequest successfully');
    debug(anonymousGetLastCreatedProjectRequest);
    dispatch(loadingEndAction());
    // dispatch(companyRequestListKnownAction(anonymousGetLastCreatedProjectRequest));
    return data.anonymousGetLastCreatedProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const anonymousGetLastUpdatedProjectRequest: Function = (size: Number) => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('anonymousGetLastUpdatedProjectRequest');

  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(ANONYMOUS_GET_LAST_UPDATE_PROJECT_REQUEST, {
    size,
  });

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.anonymousGetLastUpdatedProjectRequest) {
    const anonymousGetLastUpdatedProjectRequest = data.anonymousGetLastUpdatedProjectRequest;
    debug(' anonymousGetLastUpdatedProjectRequest successfully');
    debug(anonymousGetLastUpdatedProjectRequest);
    dispatch(loadingEndAction());
    return data.anonymousGetLastUpdatedProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const anonymousGetLastUpdatedProjectNoPicturesRequest: Function = (size: Number) => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('anonymousGetLastUpdatedProjectRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(
    ANONYMOUS_GET_LAST_UPDATE_PROJECT_REQUEST_NO_PICTURES,
    {
      size,
    }
  );

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.anonymousGetLastUpdatedProjectRequest) {
    const anonymousGetLastUpdatedProjectRequest = data.anonymousGetLastUpdatedProjectRequest;
    debug(' anonymousGetLastUpdatedProjectRequest successfully');
    debug(anonymousGetLastUpdatedProjectRequest);
    dispatch(loadingEndAction());
    return data.anonymousGetLastUpdatedProjectRequest as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const getCompanyGetProjectRequestDetails: Function = (requestId: string) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('getCompanyGetProjectRequestDetails');
  const variables = {
    requestId,
  };
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(GET_PROJECT_REQUEST_DETAILS, variables);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.companyGetProjectRequestById) {
    const companyGetProjectRequestById = data.companyGetProjectRequestById;
    debug('Get companyGetProjectRequestById successfully');
    debug(companyGetProjectRequestById);
    dispatch(loadingEndAction());
    dispatch(companyRequestKnownAction(companyGetProjectRequestById));
    return companyGetProjectRequestById as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};

export const consultantGetProjectRequestById: Function = (requestId: string) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('consultantGetProjectRequestById');
  const variables = {
    requestId,
  };
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(CONSULTANT_GET_PROJECT_REQUEST_BY_ID, variables);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.consultantGetProjectRequestById) {
    const consultantGetProjectRequestById = data.consultantGetProjectRequestById;
    debug('Get consultantGetProjectRequestById successfully');
    debug(consultantGetProjectRequestById);
    dispatch(loadingEndAction());
    dispatch(companyRequestKnownAction(consultantGetProjectRequestById));
    return consultantGetProjectRequestById as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};

export const adminGetProjectRequestById: Function = (requestId: string) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('adminGetProjectRequestById');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(ADMIN_GET_PROJECT_REQUEST_BY_ID, { requestId });
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目信息失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.adminGetProjectRequestById) {
    const adminGetProjectRequestById = data.adminGetProjectRequestById;
    debug('Get adminGetProjectRequestById successfully');
    debug(adminGetProjectRequestById);
    dispatch(loadingEndAction());
    dispatch(companyRequestKnownAction(adminGetProjectRequestById));
    return adminGetProjectRequestById as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};

export const adminGetAllProjectRequest: Function = (status: FProjectRequestStatusEnum) => async (
  dispatch: Function
): Promise<ProjectRequestInfo[]> => {
  debug('adminGetAllProjectRequest');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  status;
  const { data, loading, error } = await query(ADMIN_GET_All_PROJECT_REQUEST);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目失败' }));
    return [] as ProjectRequestInfo[];
  }

  if (data && data?.adminGetAllProjectRequestByStatus) {
    const adminGetAllProjectRequestByStatus = data.adminGetAllProjectRequestByStatus;
    debug('Add companyGetAllProjectRequest successfully');
    debug(adminGetAllProjectRequestByStatus);
    dispatch(loadingEndAction());
    dispatch(companyRequestListKnownAction(adminGetAllProjectRequestByStatus));
    return data.adminGetAllProjectRequestByStatus as ProjectRequestInfo[];
  }
  return [] as ProjectRequestInfo[];
};

export const adminApproveProjectRequest: Function = (requestId: string) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('adminApproveProjectRequest');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await mutate(ADMIN_APPROVE_PROJECT_REQUEST, { requestId });
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '项目审核通过失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.adminApproveProjectRequest) {
    const adminApproveProjectRequest = data.adminApproveProjectRequest;
    debug('Add adminApproveProjectRequest successfully');
    debug(adminApproveProjectRequest);
    dispatch(loadingEndAction());
    dispatch(companyRequestKnownAction(adminApproveProjectRequest));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '项目审核通过成功',
      })
    );
    return adminApproveProjectRequest as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};

export const anonymousGetProjectRequestById: Function = (requestId: string) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('anonymousGetProjectRequestById');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(ANONYMOUS_GET_PROJECT_REQUEST_BY_ID, { requestId });
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取项目信息失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.anonymousGetProjectRequestById) {
    const anonymousGetProjectRequestById = data.anonymousGetProjectRequestById;
    debug('Get anonymousGetProjectRequestById successfully');
    debug(anonymousGetProjectRequestById);
    dispatch(loadingEndAction());
    // dispatch(companyRequestKnownAction(anonymousGetProjectRequestById));

    return anonymousGetProjectRequestById as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};
